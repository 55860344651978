// Media Object
.media-bordered {
  .media {
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: 0;
    }
  }
}
.media {
  padding: 1rem 0;
}

// Pagination
.page-link {
  border-radius: 0.35rem;
  margin-left: 5px;
}
