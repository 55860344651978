@import "../core/variables/variables";
@import "../core/variables/app-variables";
@import "../core/mixins/shadows";

.toast-container > div {
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
  border-radius: $border-radius;
  border: none;
  @include card-shadow();
}

.toast-top-full-width,
.toast-bottom-full-width {
  & > div {
    max-width: 100%;
  }
}

.toast {
  background-color: $primary;
}
.toast-success {
  background-color: $success;
}
.toast-error {
  background-color: $danger;
}
.toast-info {
  background-color: $info;
}
.toast-warning {
  background-color: $warning;
}
