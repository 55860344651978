@import "../core/variables/variables";
@import "../core/variables/app-variables";

// apex chart tooltip
.apexcharts-tooltip,
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
    color: $body-color !important;
}

// icons
.apexcharts-toolbar {
    [class*=apexcharts-] {
        svg {
            fill: $text-muted;
        }
        &:hover svg {
            fill: $text-muted !important;
        }
    }
}