// center vertically and/or horizontally an absolute positioned element

@mixin center($xy: xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease;
  } @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    transition: all 0.25s ease;
  } @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    transition: all 0.25s ease;
  }
}
